import {osintDataUrl} from "@/main";
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import {uniqueId} from "lodash";
import {mapActions, mapGetters} from "vuex";
// import Vue from 'vue'
// import HighchartsVue from 'highcharts-vue'
// Vue.use(HighchartsVue)
// import {Chart} from 'highcharts-vue'
// import Highcharts from 'highcharts'
// import NetworkGraph from 'highcharts/modules/networkgraph'

// // window.Highcharts = Highcharts;
// NetworkGraph(Highcharts)

import D3Network, {W} from "vue-d3-network";
import axios from "@/axios";
// var D3Network = window['vue-d3-network']
import {Network, DataSet} from "vis-network/standalone";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import osintGraphFilter from "./osintGraphFilter";
import "keylines";
import KlChart from "@/components/Chart";

export default {
    name: "OsintGraph",
    props: {
        fetchingCaseData: {},
        activeToolSection: {
            type: String,
            default: null,
        },
        graphNodes: {
            type: Array,
            default: [],
        },
        graphLinks: {
            type: Array,
            default: [],
        },
        searchResults: {
            // type: Object,
            // default: {},
        },
        expandedGraph: {
            type: Boolean,
            default: false,
        },
        showOptionsPanel: {
            type: Boolean,
        },
    },
    components: {
        KlChart,
        // highcharts: Chart
        VueMultiselect,
        D3Network,
        "neo-button": Button,
        osintGraphFilter,
        "neo-checkbox": Checkbox,
    },
    data() {
        return {
            data: {
                type: "LinkChart",
                items: [
                    {
                        id: "node1",
                        type: "node",
                        t: "Hello World",
                        c: "blue",
                    },
                ],
            },
            graphStabilizationPercentage: 0,
            stabilizing: false,
            stabilizedAtCount: -11,
            showLoader: false,
            valuesFromfilters: {
                typeFilter: [],
            },
            showFiltersRestoreButton: false,
            layout: null,
            highlightedNodes: [],
            filteredNodes: [],
            persistToolFilters: [],
            nodeConnectionCount: null,
            caseDataClicked: 0,
            toggleToolData: false,
            casedata: false,
            possibleSearchTypes: [],
            toolType: [],
            visibleNodes: [],
            nodeDegree: null,
            toggleCaseData: false,
            collapseAll: false,
            filters: [],
            rightClickedNode: null,
            clusterEdgeColor: null,
            showColorPicker: false,
            graphFilters: [],
            shortestPathNodeOne: null,
            shortestPathNodeTwo: null,
            focusNode: null,
            splitGraphView: false,
            value: "",
            options: ["f-one", "f-two", "f-three"],
            // graphFilters: {
            //   nodeValues: [],
            //   entityValues: [],
            //   sourceFilter: [],
            // },
            ignoreKeysMap: {
                twitter: ["profile", "created_at", "description", "lang", "translator_type", "source"],
                linkedin: ["profile_pic", "smtp_type", "type", "score", "confidence"],
            },
            keyFormatters: {
                twitter: {
                    name: (x) => x.split()[0],
                },
                linkedin: {
                    name: (x) => x.split(" ")[0],
                },
            },
            formControls: {
                username: {
                    linkedin: {
                        name: "",
                        company: "",
                        nodesAttachTo: "name",
                    },
                    twitter: {
                        username: "",
                    },
                },
            },

            networkData: [],
            searchType: null,
            searchString: "",
            nodes: [],
            searchFilterType: "",
            username: "",
            companyName: "",

            filterValueApiMap: {
                email: [
                    {
                        source: "linkedin",
                        api: "/linkedin/search/email",
                        keys: ["location", "current_title", "current_employer", "current_work_email", "current_personal_email"],
                    },
                    {source: "domain", api: "/api/domain/email", keys: []},
                ],
                username: [
                    {source: "twitter", api: "/twitter/user", keys: ["name"]},
                    {
                        source: "linkedin",
                        api: "/linkedin/search/name/company",
                        keys: ["name", "location", "current_title", "current_employer", "current_work_email", "current_personal_email", "emails"],
                    },
                    // {api:'/twitter/user/followers' ,keys:[]},
                    // {api:'/twitter/user/friends' ,keys:[]},
                    // {api:'/username' ,keys:[]},
                ],
            },
            nodeCount: 0,
            nodes: [],
            links: [],
            nodeSize: 20,
            canvas: false,
            graphSeed: null,
            nodePositions: {},
            nodeDegreeOptions: [
                {
                    group: "Connection Count",
                    categories: [1, 2, 3, 4],
                },
            ],
            connectionCountOptions: [
                {
                    group: "Connection Count",
                    categories: [
                        {id: 1, label: "1+"},
                        {id: 2, label: "2+"},
                        {id: 3, label: "3+"},
                        {id: 4, label: "4+"},
                        {id: 5, label: "5+"},
                        {id: 6, label: "6+"},
                        {id: 7, label: "7+"},
                        {id: 8, label: "8+"},
                        {id: 9, label: "9+"},
                        {id: 10, label: "10+"},
                    ],
                },
            ],
        };
    },
    computed: {
        getValuesAfterFilters() {
            let nodesSet = [];

            //NOTE:(Kshitij): Replace this abomination of a code with a state machine!!
            if (this.toggleToolData && this.toggleCaseData && this.possibleSearchTypes.length === 0 && this.toolType.length === 0) {
                nodesSet = this.highlightedNodes;
            } else if (this.toggleToolData && this.toggleCaseData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                } else if (this.toolType.length > 0 && this.possibleSearchTypes.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.graphFilters.length > 0) {
                nodesSet = this.filteredNodes;
            } else if (this.toggleCaseData && !this.toggleToolData && this.possibleSearchTypes.length === 0) {
                nodesSet = this.getCaseDataNodes().map((e) => e.id);
            } else if (this.toggleCaseData && !this.toggleToolData && this.possibleSearchTypes.length > 0) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    if (this.filteredNodes.length > 0) {
                        nodesSet = this.filteredNodes;
                    } else {
                        nodesSet = this.valuesFromfilters["typeFilter"];
                    }
                }
            } else if (this.toggleCaseData && this.toggleToolData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.toggleCaseData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.toggleToolData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    if (this.filteredNodes.length > 0) {
                        nodesSet = this.filteredNodes;
                    } else {
                        nodesSet = this.valuesFromfilters["typeFilter"];
                    }
                } else if (this.toolType.length > 0) {
                    nodesSet = this.highlightedNodes;
                }
            }
            // else if(this.toggleCaseData & this.possibleSearchTypes.length > 0)
            // {
            //     nodesSet = this.highlightedNodes
            // }
            let uniqueSet = new Set();
            let arr = [];
            for (let n of this.graphNodes) {
                if (
                    nodesSet.includes(n.id) &&
                    /*NOTE: (Kshitij) check why duplicates in the first place?.*/
                    !uniqueSet.has(n.id)
                ) {
                    uniqueSet.add(n.id);
                    arr.push(n);
                }
            }
            return arr;
        },

        isCaseDataToggleActive() {
            return this.toggleCaseData;
        },
        isToolDataToggleActive() {
            return this.toggleToolData;
        },

        isIndependentFiltersDisabled() {
            return !(this.toggleToolData || this.toggleCaseData);
        },

        // isToolFilterDisabled() {
        //   const c1 = !this.toggleToolData
        //   const c2 = this.persistToolFilters.length === 0
        //   return c1 && c2
        // },

        isToolFilterDisabled() {
            return !this.toggleToolData;
        },

        isTypesDisabled() {
            return this.toolType.length === 0;
        },

        isAddValueButtonDisabled() {
            const c1 = this.getFilterValues.length === 0;
            const c2 = this.possibleSearchTypes.length === 0;
            return c1 || c2;
        },
        getFilterValues() {
            let fv = [];
            // NOTE: (KSHITIJ) Check later why duplicates in the first place?
            let unique = new Set();
            fv = this.graphNodes.filter((n) => {
                const f1 = this.valuesFromfilters["typeFilter"].includes(n.id);
                if (f1) {
                    const indexInAppliedFilters = this.graphFilters.findIndex((o) => {
                        return o.filterVal.label === n.label;
                    });
                    if (indexInAppliedFilters < 0 && !unique.has(n.id)) {
                        unique.add(n.id);
                        return true;
                    }
                    return false;
                }
                return false;
            });
            return fv;
        },

        tools() {
            return [...new Set(this.graphNodes.map((n) => n.source).filter((n) => n !== undefined))].map((n) => ({id: n, label: n}));
        },

        isFilterPanelExpaned() {
            return this.graphFilters.length >= 1;
        },
        showGraphSaveButton() {
            return this.graphFilters.nodeValues.length <= 0 && this.graphFilters.entityValues.length <= 0 && this.graphFilters.sourceFilter.length <= 0;
        },
        sources() {
            let vals = [];
            let seen = [];
            this.graphNodes.forEach((n, idx) => {
                if (!(n.source in seen) && n.source !== undefined) {
                    vals.push({label: n.source, id: idx});
                    seen[n.source] = true;
                }
            });
            return vals;
        },
        filterTypes() {
            // return this.filters.filter(e => {
            //   return e.active === true
            // })
            return this.possibleSearchTypes;
        },
        filterValues() {
            // return this.nodeValues
            return this.getActiveNodes;
        },

        searchTypes() {
            // if (this.toolType === null) return [];
            let vals = [];
            let seen = {};

            let nodesSet = [];
            if (this.toggleCaseData) {
                nodesSet = nodesSet.concat(this.getCaseDataNodes());
            }
            if (this.toggleToolData) {
                nodesSet = nodesSet.concat(this.getToolDataNodes());
            }
            // const toolFilters = this.toolType.map(e => e.label)
            // let activeNodes = this.graphNodes.filter(n => toolFilters.includes(n.source));
            nodesSet.forEach((n) => {
                var type = n.type;
                if (!(type in seen) && type !== undefined) {
                    vals.push({label: type, id: n.id});
                    seen[type] = true;
                }
            });
            return vals;
        },

        toolsList() {
            return [{group: "Tools", categories: this.tools}];
        },

        searchTypesList() {
            return [{group: "Search Types", categories: this.searchTypes}];
        },

        getActiveNodesList() {
            return [{group: "Active Nodes", categories: this.getActiveNodes}];
        },

        getValuesAfterFiltersList() {
            return [{group: "Shortest Path Node", categories: this.getValuesAfterFilters}];
        },

        nodeValues() {
            const vals = this.graphNodes;
            return vals;
        },

        getActiveNodes() {
            let visibleNodes = [];
            if (this.highlightedNodes.length > 0) {
                // visibleNodes = this.graphNodes.filter(n => !n.hidden && this.highlightedNodes.includes(n.id))
                visibleNodes = this.highlightedNodes;
                visibleNodes = visibleNodes.map((nid) => {
                    return window.networkx.body.data.nodes.get(nid);
                });
            } else {
                visibleNodes = this.graphNodes.filter((n) => !n.hidden);
            }
            // return [...visibleNodes]
            return visibleNodes;
        },
        iconCache() {
            let iconData = {};
            let icons = [...this.$store.getters.getIconsData, ...this.$store.getters.getSocialPlatforms];
            for (let i = 0; i < icons.length; i++) {
                iconData[icons[i].key] = icons[i].icon_data;
            }
            return iconData;
        },
    },
    watch: {
        // expandedGraph(newVal, oldVal) {
        //     if (newVal === true) {
        //         var physix = {
        //             physics: {
        //                 enabled: true,
        //                 forceAtlas2Based: {
        //                     gravitationalConstant: -26,
        //                     centralGravity: 0.005,
        //                     springLength: 230,
        //                     springConstant: 0.18,
        //                 },
        //                 maxVelocity: 146,
        //                 solver: "forceAtlas2Based",
        //                 timestep: 0.35,
        //                 stabilization: { iterations: 600 },
        //             },
        //         }
        //         window.network.setOptions(physix)
        //         if (this.stabilizedAtCount !== this.graphNodes.length) {
        //             window.network.stabilize(100)
        //             this.stabilizedAtCount = this.graphNodes.length
        //         }
        //     }
        // },
        activeToolSection(newVal, oldVal) {
            this.onToolChange(newVal);
        },
        graphNodes: {
            deep: true,
            handler(newvalue, oldvalue) {
                // this.graphNodes = newvalue
                // this.updateNodes(newvalue);
            },
        },
        graphLinks(newvalue, oldvalue) {
            // this.graphLinks = newvalue
            // this.updateLinks(newvalue);
        },
    },
    async mounted() {
        // this.genGraph();
        // if (!this.toggleCaseData && !this.toggleToolData) {
        //     this.hideAllNodes();
        // }

        // var tw_data = this.removeDirtyKeys(this.flattenDict(this.dummyData), 'twitter')
        // var formatted  = this.applyKeyFormatters(tw_data, 'twitter')
        // this.createNetwork(this.networkData, formatted)
        // var li_data = this.removeDirtyKeys(this.flattenDict(this.li), 'linkedin')
        // var formatted  = this.applyKeyFormatters(li_data, 'linkedin')
        this.filters = new Array(this.searchTypes.length);
        if (this.activeToolSection) {
            this.onToolChange(this.activeToolSection);
        }
    },
    created() {},

    methods: {
        klReady(chart) {
            this.chart = chart;
        },

        ...mapActions(["fetchStaticData"]),
        onSelectConnectionCount() {
            this.nodeDegree = null;
            this.executeFilter();
        },
        onSelectConnectionDegree() {
            this.nodeConnectionCount = null;
            this.executeFilter();
        },
        onToolChange(tool) {
            // this.toggleToolData = true
            // if (!tool) return;
            // let filters = this.persistToolFilters;
            // let activeFilter = [{ id: tool, label: tool }];
            // if (!filters.map((e) => e.label).includes(tool)) filters = filters.concat(activeFilter);
            // this.toolType = filters;
            // if (filters.length > 0) {
            //     this.toggleToolData = true;
            // }
            // this.executeFilter();
            // window.networkx.fit();
        },
        removeActiveToolToPersistedFiltersList() {
            const index = this.persistToolFilters.findIndex((e) => e.label === this.activeToolSection);
            if (index !== -1) {
                this.persistToolFilters.splice(index, 1);
                this.$toast.success("Removed From graph");
            }
        },
        stabilizeGraph() {
            // this.graphStabilizationPercentage = 0
            this.stabilizing = true;
            var physix = {
                physics: {
                    enabled: true,
                    forceAtlas2Based: {
                        gravitationalConstant: -26,
                        centralGravity: 0.005,
                        springLength: 230,
                        springConstant: 0.18,
                    },
                    maxVelocity: 146,
                    solver: "forceAtlas2Based",
                    timestep: 0.35,
                    stabilization: {iterations: 100, updateInterval: 25},
                },
            };
            window.networkx.setOptions(physix);
            window.networkx.stabilize(100);

            // window.networkx.stopSimulation()
        },
        addActiveToolToPersistedFiltersList() {
            this.persistToolFilters.push({id: this.activeToolSection, label: this.activeToolSection});
            this.$toast.success("Added to graph");
        },

        onToggleToolData() {
            this.toggleToolData = !this.toggleToolData;
            const filters = this.toolType ? this.toolType.map((n) => n.label) : [];
            let nodesToUpdate = [];
            if (this.toggleToolData) {
                for (const node of this.graphNodes) {
                    if (!node.isCaseData && filters.includes(node.source)) {
                        node.hidden = false;
                        nodesToUpdate.push({id: node.id, hidden: false});
                    }
                }
            } else {
                for (const node of this.graphNodes) {
                    if (!node.isCaseData) {
                        node.hidden = true;
                        nodesToUpdate.push({id: node.id, hidden: true});
                    }
                }
            }
            window.networkx?.body?.data?.nodes?.update(nodesToUpdate);
            this.executeFilter();
            // this.resetAllFilters()
        },
        onToggleCaseData() {
            this.toggleCaseData = !this.toggleCaseData;
            if (this.toggleCaseData) {
                if (this.casedata) {
                    let nodesToShow = [];
                    this.graphNodes.forEach((node) => {
                        if (node.isCaseData) {
                            // node.hidden = false;
                            this.highlightedNodes.push(node.id);
                            nodesToShow.push({id: node.id, hidden: false});
                        }
                    });
                    window.networkx.body.data.nodes.update(nodesToShow);
                    this.executeFilter();
                } else {
                    this.casedata = true;
                    this.$emit("getCaseData");
                }
            } else {
                let nodesToHide = [];
                this.graphNodes.forEach((node) => {
                    if (node.isCaseData) {
                        // node.hidden = true;
                        nodesToHide.push({id: node.id, hidden: true});
                        const idx = this.highlightedNodes.indexOf(node.id);
                        this.highlightedNodes.splice(idx, 1);
                    }
                });
                window.networkx.body.data.nodes.update(nodesToHide);

                this.$emit("removeCaseData", window.networkx.getPositions());
            }
            // this.executeFilter()
            // this.resetAllFilters()
        },

        closeGraphPanel() {
            /* Refactor these lines into function */
            const popup = this.getOptionsPopupRef();
            popup.style.display = "none";
            /* ... */
            this.$emit("graphClose");
        },

        onInput(index, type, event) {
            this.$set(this.filters, index, {...type, active: event});
        },

        onNetworkAreaClicked() {
            const popup = this.getOptionsPopupRef();
            popup.style.display = "none";
        },

        collapseGraphNodes() {
            const collapsed = window.networkx.body.data.nodes.get(this.rightClickedNode).collapsed;
            const nodes = window.networkx.getConnectedNodes(this.rightClickedNode);

            var arr = [];

            if (collapsed) {
                var config = {hidden: false, borderWidth: 0, opacity: 1.0, font: {color: "black"}, color: {background: "#ffffff", border: "#363D43"}, imagePadding: 14};
                arr.push({id: this.rightClickedNode, collapsed: false, borderWidth: 0, size: 35, color: {background: "#ffffff"}});
            } else {
                var config = {hidden: true};
                arr.push({id: this.rightClickedNode, collapsed: true, borderWidth: 4, size: 45, color: {border: "purple", background: "#ffffff"}});
            }

            let highlighted = null;
            for (let n of nodes) {
                highlighted = window.networkx.body.data.nodes.get(n)._highlighted;
                if (highlighted !== true) {
                    var obj = {};
                    Object.assign(obj, config);
                    obj.id = n;
                    arr.push(obj);
                }
            }
            window.networkx.body.data.nodes.update(arr);
        },

        updateClusterEdgeColor(event) {
            let color = event.target.value;

            const edges = window.networkx.getConnectedEdges(this.rightClickedNode);
            var arr = [];
            var config = {color: color};
            for (var e = 0; e < edges.length; e++) {
                var obj = {};
                Object.assign(obj, config);
                obj.id = edges[e];
                arr.push(obj);
            }

            window.networkx.body.data.edges.update(arr);
        },

        getGraphClusterColorPicker() {
            return document.getElementById("graphClusterColorPicker");
        },

        openColorPicker() {
            // this.showColorPicker = true
            const colorPicker = this.getGraphClusterColorPicker();
            // colorPicker.style.visibility = 'visible'
            colorPicker.click();
        },
        collapseAllFilters() {
            this.collapseAll = !this.collapseAll;
            this.graphFilters.forEach((e) => (e.expanded = !e.expanded));
        },
        onRemoveFilter(index) {
            this.graphFilters.splice(index, 1);
            this.filterGraph();
        },
        shouldCollapseValueFilter(filter) {
            return this.collapseAll === true && filter.expanded === false ? true : false;
        },

        onFilterValueSelect(index, data) {
            this.graphFilters[index]["filterKey"] = data.filterKey;
            this.graphFilters[index]["filterVal"] = data.filterVal;
            this.graphFilters[index]["expanded"] = true;
            // this.filterGraph()
            this.executeFilter();
        },

        addGraphFilter() {
            if (this.possibleSearchTypes.length === 0) return;
            if (this.getFilterValues.length === 0) return;
            const filterLength = this.graphFilters.length;
            if (filterLength === 0 || (this.graphFilters[filterLength - 1].filterVal !== "" && filterLength >= -1)) {
                this.graphFilters.push({
                    filterVal: "",
                    id: uniqueId("filter_"),
                });
            }
        },
        findShortestPath() {
            if (this.shortestPathNodeOne && this.shortestPathNodeTwo) {
                var start = this.shortestPathNodeOne.id;
                var dest = this.shortestPathNodeTwo.id;
            }
        },
        setHierarchy(direction) {
            this.layout = direction;
            this.graphSeed = window.networkx.getSeed();
            this.nodePositions = window.networkx.getPositions();
            window.networkx.setOptions({
                physics: {enabled: false},
                layout: {
                    hierarchical: {
                        direction: direction,
                        levelSeparation: 450,
                        nodeSpacing: 200,
                        treeSpacing: 10,
                        blockShifting: true,
                        edgeMinimization: true,
                        parentCentralization: true,
                        sortMethod: "hubsize", // hubsize, directed
                        shakeTowards: "leaves", // roots, leaves
                    },
                },
            });
            // window.networkx.layoutEngine.options.hierarchical.enabled = true
            window.networkx.redraw();
        },
        setRandomSeed() {
            this.layout = null;
            let nodesToUpdate = [];
            for (const [nodeId, position] of Object.entries(this.nodePositions)) {
                nodesToUpdate.push({id: nodeId, ...position});
            }
            window.networkx.body.data.nodes.update(nodesToUpdate);

            window.networkx.setOptions({
                layout: {
                    randomSeed: this.graphSeed,
                    hierarchical: {
                        enabled: false,
                    },
                },
            });
            window.networkx.redraw();
            window.networkx.setOptions({
                physics: {
                    enabled: true,
                    forceAtlas2Based: {
                        gravitationalConstant: -26,
                        centralGravity: 0.005,
                        springLength: 230,
                        springConstant: 0.18,
                    },
                    maxVelocity: 146,
                    solver: "forceAtlas2Based",
                    timestep: 0.35,
                    stabilization: {iterations: 100, updateInterval: 25},
                },
            });
        },
        focusOnNode() {
            if (!this.focusNode) return;
            var options = {
                // position: {x:positionx,y:positiony}, // this is not relevant when focusing on nodes
                scale: 1.0,
                offset: {x: 10, y: 10},
                animation: {
                    duration: 1000,
                    easingFunction: "easeInOutQuad",
                },
            };

            window.networkx.focus(this.focusNode.id, options);
        },
        clearGraph() {
            // window.networkx.body.data.nodes = [];

            window.networkx.setData({nodes: [], edges: []});
            window.networkx.redraw();
        },
        async saveGraphData() {
            const case_id = this.$store.getters.getCaseId;
            const url = "/analysis-tools/graph";
            this.graphNodes.forEach((n) => {
                const pos = window.networkx.getPosition(n.id);
                n.x = pos.x;
                n.y = pos.y;
            });
            const data = {
                case_id: case_id,
                nodes: this.graphNodes,
                edges: this.graphLinks,
            };
            try {
                const result = await axios.post(url, data);
                this.$toast.success("Graph saved");
            } catch (error) {
                // this.$toast.error("Graph saving failed");
            }
        },

        showAllGraphNodes() {
            this.graphNodes.forEach((n) => {
                n.hidden = false;
                n._highlighted = false;
            });
        },

        removeEntityTypeFilters() {
            this.graphFilters = [];
        },

        /* If there are filtered nodes, return  them ,
If there are highlighted nodes return all highlighted nodes,
If none, return all nodes*/
        getNodesSet() {
            let nodesSet = [];
            // if(this.toggleCaseData && !this.toggleToolData){
            //   nodesSet =
            // }

            if (this.highlightedNodes.length > 0) {
                nodesSet = this.highlightedNodes;
            }
            if (this.filteredNodes.length > 0) {
                nodesSet = this.filteredNodes;
            }
            return nodesSet;
        },

        /* CLear any type of filter,
            type filter / shortest node filter / etc..
            clearhighlighs
        */
        clearShortestLinkHighlight() {
            // this.possibleSearchTypes = [];

            var arr = [];
            let config = null;
            // var opacityConfig = { opacity: 1, size: 30, _highlighted: false, hidden: false, font: { color: "#000000" }, borderWidth: 0, color: { background: "#ffffff", border: "#ffffff" }, imagePadding: 14 };
            // var queryNodeConfig = { opacity: 1, _highlighted: false, hidden: false, font: { color: "black" } };
            // var centerNodeConfig = {
            //     opacity: 1,
            //     _highlighted: false,
            //     hidden: false,
            //     color: { border: "#4220FB", font: "#fff", background: "#6E6EFD" },
            //     borderWidth: 2,
            // };
            for (let n of this.highlightedNodes) {
                var obj = {};
                // let attr = window.networkx.body.data.nodes.get(n);
                // if (attr.type === "multi_query_center_node") {
                //     config = centerNodeConfig;
                // } else if (attr.isSubQueryNode === true) {
                //     config = queryNodeConfig;
                // } else {
                //     config = opacityConfig;
                // }
                Object.assign(obj, config);
                obj.id = n;
                arr.push(obj);
            }
            this.chart.show(
                obj.map((e) => e.id),
                true
            );

            // var edge_arr = [];
            // for (let e of this.getActivePlottedLinks()) {
            //     var obj = {};
            //     var edgeConfig = {
            //         width: 1,
            //         color: "#282C35",
            //     };
            //     Object.assign(obj, edgeConfig);
            //     obj.id = e;
            //     edge_arr.push(obj);
            // }

            // window.networkx.body.data.nodes.update(arr);
            // window.networkx.body.data.edges.update(edge_arr);

            // If entity type filterrs were applied we would want to remove those also.

            if (this.graphFilters.length >= 1) {
                this.removeEntityTypeFilters();
            }
        },

        getActivePlottedLinks() {
            return Object.keys(window.networkx.body.edges);
        },

        /* Highlight Nodes based on the applied filters,
highlighting can be different based on the `type` parameter.
if we are filteriongby search type for ex,  we would want to see only those nodes.ex, emails.
& we dont want to highlight edges in these cases, but letes say we are highlighting,
shorted path, we also want to highlight edges.
*/
        highlightNodes(nodes, type) {
            var arr = [];
            var visibleNodesConfig = {hidden: false, borderWidth: 1, opacity: 1.0, font: {color: "black"}, color: {background: "#ffffff", border: "#363d43"}, imagePadding: 14};

            var centerNodeHighlightConfig = {hidden: false, opacity: 1.0};
            var centerNodeHideConfig = {hidden: false, opacity: 0.1};

            var opacityConfig = {hidden: false, opacity: 0.1, color: "#ffffff", font: {color: "FFFFFF"}};
            for (let node of this.graphNodes) {
                var obj = {};
                let attr = window.networkx.body.data.nodes.get(node.id);
                if (!nodes.includes(node.id)) {
                    /*If the node is hidden in visjs dataset, it means
some tooltype filter was applied, so we dont want to do anything with
those nodes, we can(should) skip those. */
                    if (attr.hidden) continue;

                    if (attr.type === "multi_query_center_node") {
                        Object.assign(obj, centerNodeHideConfig);
                    } else {
                        Object.assign(obj, opacityConfig);
                    }
                    obj._highlighted = false;
                    obj.id = node.id;
                } else {
                    if (attr.type === "multi_query_center_node") {
                        Object.assign(obj, centerNodeHighlightConfig);
                    } else {
                        Object.assign(obj, visibleNodesConfig);
                    }
                    obj.id = node.id;
                    obj._highlighted = true;
                }
                arr.push(obj);
            }

            // const edges = this.findConnectedPath(arr.filter(n => n._highlighted === true).map(o => o.id))
            const edges = this.findConnectedPath(arr.filter((n) => n._highlighted === true).map((o) => o.id));
            if (type !== "search_type") this.highlightEdges(edges);
            window.networkx.body.data.nodes.update(arr);
        },

        // filterNodesByTool() {
        //   // this.highlightedNodes = [];
        //   const filters = this.toolType.map(n => n.label)
        //   let nodesToUpdate = [];
        //   if (filters.length === 0) {
        //     for (const node of this.graphNodes) {
        //       if (!node.isCaseData) {
        //         node.hidden = true;
        //         nodesToUpdate.push({ id: node.id, hidden: true })
        //       }
        //     }
        //   } else {
        //     for (let node of this.graphNodes) {
        //       if (!node.isCaseData) {
        //         if (filters.includes(node.source)) {
        //           // this.highlightedNodes.push(node.id)
        //           node.hidden = false;
        //           nodesToUpdate.push({ id: node.id, hidden: false })
        //         } else {
        //           node.hidden = true;
        //           nodesToUpdate.push({ id: node.id, hidden: true })
        //         }
        //       }
        //     }
        //   }
        //   // this.highlightedNodes = nodesToUpdate
        //   window.networkx.body.data.nodes.update(nodesToUpdate);
        //   let nodeToFocus = this.graphNodes.find(n => n.type === "multi_query_center_node" && n.source === filters[filters.length - 1])
        //   window.networkx.focus(nodeToFocus.id);

        // },

        emptySearchTypeFilter() {
            this.possibleSearchTypes = [];
            this.graphFilters = [];
            // this.clearShortestLinkHighlight();
            let nodesToUpdate = [];
            for (const node of this.graphNodes) {
                node.hidden = true;
                nodesToUpdate.push({id: node.id, hidden: true});
            }
            window.networkx.body.data.nodes.update(nodesToUpdate);
            this.executeFilter();
            // this.resetAllFilters()
        },

        getNodeAttrById(nid, attr) {
            return window.networkx.body.data.nodes.get(nid)[attr];
        },

        resetHighlightedNodes() {
            var reset_arr = [];
            var resetFadedNodeConfig = {opacity: 1.0, borderWidth: 0, font: {color: "black"}};
            for (let n of this.highlightedNodes) {
                var obj = {};
                Object.assign(obj, resetFadedNodeConfig);
                obj.id = n;
                reset_arr.push(obj);
            }
            window.networkx.body.data.nodes.update(reset_arr);
        },
        resetHighAllNodes() {
            var reset_arr = [];
            var resetFadedNodeConfig = {opacity: 1.0, borderWidth: 0, font: {color: "black"}};
            for (let n of this.graphNodes) {
                var obj = {};
                Object.assign(obj, resetFadedNodeConfig);
                obj.id = n.id;
                reset_arr.push(obj);
            }
            window.networkx.body.data.nodes.update(reset_arr);
        },

        getToolDataNodes() {
            return this.graphNodes.filter((e) => e.isCaseData !== true);
        },

        getCaseDataNodes() {
            return this.graphNodes.filter((e) => e.isCaseData === true);
        },

        /**
         *
         * @param {str} type - unhide given type from graph
         */
        unhide() {
            let arr = [];
            let config = {hidden: false};

            let nodesToUnhide = [];

            if (this.toggleToolData) {
                let toolNodes = this.getToolDataNodes();
                nodesToUnhide = nodesToUnhide.concat(toolNodes);
            }
            // if (this.toggleCaseData) {
            //     let caseNodes = this.getCaseDataNodes();
            //     nodesToUnhide = nodesToUnhide.concat(caseNodes);
            // }

            for (let n of nodesToUnhide) {
                var obj = {};
                Object.assign(obj, config);
                obj.id = n.id;
                arr.push(obj);
            }
            window.networkx.body.data.nodes.update(arr);
        },

        clearGraphOnCanvas() {
            this.hideGraphPopup();
            this.resetAllFilters();
            this.hideAllNodes();
            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            this.graphFilters = [];
        },
        hideAllNodes() {
            let arr = [];
            let hideConfig = {hidden: true};
            for (let n of this.graphNodes) {
                var obj = {};
                Object.assign(obj, hideConfig);
                obj.id = n.id;
                arr.push(obj);
            }
            window.networkx.body.data.nodes.update(arr);
            this.highlightedNodes = [];
        },

        isSomeFiltersApplied() {
            // let tools = this.toolType;
            let entityType = this.possibleSearchTypes;
            let connectionCount = this.nodeConnectionCount;
            // let caseData = this.toggleCaseData;

            if (
                // tools.length === 0 &&
                entityType.length === 0 &&
                !connectionCount
                // && caseData === false
            ) {
                return false;
            }
            return true;
        },

        unhideCaseData() {
            //NOTE(Ashish) : ashish is supposed to fix this.
            let arr = [];
            let config = {hidden: false};

            let nodesToUnhide = [];

            let caseNodes = this.getCaseDataNodes();
            nodesToUnhide = nodesToUnhide.concat(caseNodes);

            for (let n of nodesToUnhide) {
                var obj = {};
                Object.assign(obj, config);
                obj.id = n.id;
                arr.push(obj);
            }
            window.networkx.body.data.nodes.update(arr);
        },
        hideNodes(nodes) {
            let arr = [];
            let hideConfig = {hidden: true};
            for (let n of nodes) {
                var obj = {};
                Object.assign(obj, hideConfig);
                obj.id = n.id;
                arr.push(obj);

                let hidx = this.highlightedNodes.indexOf(n.id);
                if (hidx !== -1) {
                    this.highlightedNodes.splice(hidx, 1);
                }
            }
            window.networkx.body.data.nodes.update(arr);
        },

        /**
         * This is the main function that is executed when a filter is
         * applied/removed
         */
        executeFilter() {
            this.showLoader = true;

            let toolNodes = this.getToolDataNodes();
            if (!this.isSomeFiltersApplied()) {
                this.resetHighAllNodes();
                if (this.toggleCaseData) {
                    this.unhideCaseData();
                }
                if (this.toolType.length === 0) {
                    this.hideNodes(toolNodes);
                }
            }

            this.resetHighlightedNodes();
            this.resetHighlightedEdges();
            /* Get all the filters applied & then run them in order */
            let tools = this.toolType;
            let entityType = this.possibleSearchTypes;
            let entityValuesFilters = this.graphFilters;
            let connectionCount = this.nodeConnectionCount;

            if (this.possibleSearchTypes.length === 0) {
                this.graphFilters = [];
                this.nodeDegree = null;
            }

            if (tools.length >= 1 && this.toggleToolData) {
                this.filterNodesByTool();

                if (this.graphFilters.length > 0) {
                    const tools = this.toolType.map((e) => e.label);
                    const types = this.possibleSearchTypes.map((e) => e.label);
                    let idxs = [];
                    this.graphFilters.forEach((e, idx) => {
                        if (!tools.includes(e.filterVal.source) && !types.includes(e.filterVal.type)) {
                            idxs.push(idx);
                        }
                    });

                    idxs.forEach((idx) => {
                        this.graphFilters.splice(idx, 1);
                    });
                }
            }

            if (entityType.length > 0) {
                if (tools.length === 0) {
                    //   /* If no tools selected, but there could be some type filter selected,
                    //   so we need to unhide appropriate nodes (tools/casedata/both) */
                    this.unhide();
                    this.highlightNodes([]);
                }
                const filtersToKeep = this.possibleSearchTypes.map((e) => e.label);
                // Change again value filters accordingly.
                let valueFiltersToRemove = [];
                this.graphFilters.forEach((e) => {
                    if (!filtersToKeep.includes(e.filterVal.type)) {
                        valueFiltersToRemove.push(e.id);
                    }
                });

                this.graphFilters = this.graphFilters.filter((f) => !valueFiltersToRemove.includes(f.id));
                this.filterBySearchType();
                if (entityValuesFilters.length > 0) {
                    this.filterGraph();
                }
            }
            if (connectionCount !== null) {
                this.getNodesByConnectionCount();
            }
            if (this.nodeDegree) {
                // this.connectionCount = null;
                this.getNodesByDegree();
            }
            this.showLoader = false;
        },

        filterNodesByTool() {
            // this.highlightedNodes = [];
            var arr = [];
            var config = {hidden: true};
            var show = {hidden: false, opactity: 1.0};
            const filters = this.toolType.map((n) => n.label);
            for (let n of this.graphNodes) {
                if (!n.isCaseData) {
                    var obj = {};
                    if (!filters.includes(n.source)) {
                        Object.assign(obj, config);
                        const idx = this.highlightedNodes.indexOf(n.id);
                        if (idx >= 0) {
                            this.highlightedNodes.splice(idx, 1);
                        }
                    } else {
                        if (this.highlightedNodes.indexOf(n.id) < 0) {
                            this.highlightedNodes.push(n.id);
                        }
                        Object.assign(obj, show);
                    }
                    obj.id = n.id;
                    arr.push(obj);
                }
            }
            window.networkx.body.data.nodes.update(arr);
        },

        /* Filter the nodes on graph by search Type,
        ex. email, phone , address...
        fade down all non-matches & highlight with circle all matches
        */
        filterBySearchType() {
            // Reset all faded nodes
            var reset_arr = [];
            var resetFadedNodeConfig = {opacity: 1.0};

            let nodesSet = [];

            if (this.toolType.length > 0) {
                nodesSet = this.highlightedNodes;
            } else {
                /* Since search type filter is a child filter of (casdata/ tooldata top level filter) ,
                we need to tak ethis in to consideration.
                If we dont have any tool type filter selected , that means we dont
                have any highlighted nodes active on canvas. We need to consider all nodes in graph, but make sure to
                get nodes filtered by the parent filters (Case Data |  tool data | Both)
                */
                if (this.toggleCaseData) {
                    nodesSet = nodesSet.concat(this.getCaseDataNodes());
                }

                if (this.toggleToolData) {
                    nodesSet = nodesSet.concat(this.getToolDataNodes());
                }
                nodesSet = nodesSet.map((e) => e.id);
            }
            for (let n of this.highlightedNodes) {
                var obj = {};
                Object.assign(obj, resetFadedNodeConfig);
                obj.id = n;
                reset_arr.push(obj);
            }
            window.networkx.body.data.nodes.update(reset_arr);
            if (this.possibleSearchTypes.length === 0) {
                // this.graphFilters = [];
                // this.clearShortestLinkHighlight()
                return;
            }

            const searchTypes = this.possibleSearchTypes.map((e) => e.label);
            let arr = [];
            for (let node of nodesSet) {
                const n_type = this.getNodeAttrById(node, "type");
                if (searchTypes.includes(n_type)) {
                    arr.push(node);
                }
            }
            this.highlightNodes(arr, "search_type" /* used to add addional logic in highlighting */);
            this.highlightEdges([]); //Passing an empty array as input is same as  fade down all edges.
            // this.highlightedNodes = [...arr]
            this.filteredNodes = [...arr];
            this.valuesFromfilters["typeFilter"] = arr;
            // this.highlightedNodes = arr

            // this.$emit('FilterAppliedEvent')
        },

        emptyShortestLinkFields() {
            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            this.applyStoredFilters();
            // this.clearShortestLinkHighlight();
            // this.graph;
        },

        emptyToolTypeFilter() {
            let nodesToUpdate = [];
            this.toolType = [];
            this.executeFilter();
            // for (const node of this.graphNodes) {
            //   if (!node.isCaseData) {
            //     node.hidden = true;
            //     nodesToUpdate.push({ id: node.id, hidden: true })
            //   }
            // }
            // window.networkx.body.data.nodes.update(nodesToUpdate);
            // this.$emit('FilterAppliedEvent')
        },

        dashed_highlight_nodes(nodes) {
            var config = {
                scale: 1.8,
                borderWidth: 2,
                opacity: 1.0,
                font: {color: "black"},
                color: {background: "#ffffff", border: "#FE4C4C"},
                imagePadding: 14,
            };

            var arr = [];
            for (let n of nodes) {
                if (this.graphNodes.find((gn) => gn.id === n && gn.type !== "multi_query_center_node")) {
                    var obj = {};
                    Object.assign(obj, config);
                    obj.id = n;
                    arr.push(obj);
                }
            }
            window.networkx.body.data.nodes.update(arr);
        },

        getNodesByConnectionCount() {
            var arr = [];
            var connections_from_arr = [];
            let nodesSet = [];
            // const nodesSet = [...this.highlightedNodes, ...this.filteredNodes]

            /* When we have  type filter applied we want to find connection only in that,
            otherwise all highlighted nodes. */
            if (this.possibleSearchTypes.length === 0 && this.toolType.length === 0) {
                if (this.toggleCaseData) {
                    nodesSet = nodesSet.concat(this.getCaseDataNodes());
                }
                if (this.toggleToolData) {
                    nodesSet = nodesSet.concat(this.getToolDataNodes());
                }
                nodesSet = nodesSet.map((e) => e.id);
            } else {
                if (this.possibleSearchTypes.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.highlightedNodes.length > 0) {
                    nodesSet = this.highlightedNodes;
                }
            }
            const connectionCount = this.nodeConnectionCount.id;
            for (const nid of nodesSet) {
                const nodes = window.networkx.getConnectedNodes(nid);

                // if (this.nodeConnectionCount === '10+') {
                //   if (nodes.length >= 10) {
                //     arr = arr.concat([...nodes])
                //     connections_from_arr.push(nid)
                //   }
                // }
                if (nodes.length >= connectionCount) {
                    arr = arr.concat([...nodes]);
                    connections_from_arr.push(nid);
                }
            }
            arr = [...new Set(arr)];
            this.highlightNodes([...arr, ...connections_from_arr]);
            this.dashed_highlight_nodes(connections_from_arr);
        },

        fitGraphToCenter() {
            this.focusNode = null;
            window.networkx.fit();
        },

        getNodesByDegree() {
            //
            /* Filter connected nodes by node degree level */
            let degree = Number(this.nodeDegree);
            let arr = [];

            if (this.possibleSearchTypes.length > 0) {
                arr = this.filteredNodes;
            } else {
                arr = this.highlightedNodes;
            }

            while (degree >= 1) {
                let _arr = [];
                for (let i = 0; i < arr.length; i++) {
                    let connectedNodes = window.networkx.getConnectedNodes(arr[i]);
                    connectedNodes.forEach((n) => {
                        if (!arr.includes(n)) _arr.push(n);
                    });
                }
                degree--;
                arr = arr.concat(_arr);
            }
            this.highlightNodes(arr);
        },

        /* Resets the color & width to all edges to default */
        resetHighlightedEdges() {
            let config = {color: "#282C35", width: 1};
            let arr = [];
            // NOTE: (Kshitij) , this can be improved by resetign only on visible edges
            this.graphLinks.forEach((e) => {
                var obj = {};
                Object.assign(obj, config);
                obj.id = e.id;
                arr.push(obj);
            });

            window.networkx.body.data.edges.update(arr);
        },

        applyStoredFilters() {
            let filters = this.storedFilters;
            this.toolType = filters.toolType;
            this.possibleSearchTypes = filters.possibleSearchTypes;
            this.graphFilters = filters.graphFilters;

            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            // //Reset edges highlight
            // //NOTE(kshitij): This can be substantially improved in runtime.
            // //Fix it later
            // let config = { color: "#282C35" };
            // let arr = [];
            // this.graphLinks.forEach((e) => {
            //     var obj = {};
            //     Object.assign(obj, config);
            //     obj.id = e.id;
            //     arr.push(obj);
            // });

            // window.networkx.body.data.edges.update(arr);

            //show all links
            this.clearShortestLinkHighlight();

            this.executeFilter();
            this.showFiltersRestoreButton = false;
        },
        resetAllFilters() {
            // this.toggleCaseData = false;
            // this.toggleToolData = false;

            let filters = {};

            filters.toolType = this.toolType;
            filters.possibleSearchTypes = this.possibleSearchTypes;
            filters.graphFilters = this.graphFilters;

            this.toolType = [];
            this.possibleSearchTypes = [];
            this.focusNode = null;
            return filters;
        },

        shortestLink() {
            if (this.shortestPathNodeOne && this.shortestPathNodeTwo) {
                this.findShortestLink();
                const filters = this.resetAllFilters();
                this.storedFilters = filters;
                this.showFiltersRestoreButton = true;
            }
        },

        findShortestLink() {
            /* This is done by using a modified BFS,
            usually we need to maintain an adjacency list data structre for the graph,
            but VijsJs has a methods getConnectedNodes, which can be used instead of
            explicitly maintaining the structure */

            let start = null;
            let dest = null;

            try {
                start = this.shortestPathNodeOne.id;
                dest = this.shortestPathNodeTwo.id;
            } catch (err) {
                return;
            }

            //NOTE: try this optimzation later on , Crawl the graph to get connected nodes.

            let pred = {};
            let dist = {};
            //do BFS

            let queue = [];

            let visited = {};

            visited[start] = true;
            dist[start] = 0;

            queue.push(start);

            while (queue.length != 0) {
                let u = queue.shift(); // get first + remove first.
                for (let v of window.networkx.getConnectedNodes(u)) {
                    if (visited[v] === undefined) {
                        visited[v] = true;
                        dist[v] = dist[u] + 1;
                        pred[v] = u;
                        queue.push(v);
                        //stop when dest reached.
                        if (v === dest) {
                            // return;
                            let path = [];
                            let crawl = dest;
                            path.push(crawl);

                            while (pred[crawl] !== undefined) {
                                path.push(pred[crawl]);
                                crawl = pred[crawl];
                            }

                            this.highlightNodes(path);

                            const connectedEdgePath = this.findConnectedPath(path);
                            this.highlightEdges(connectedEdgePath);
                        }
                    }
                }
            }
        },

        highlightEdges(edges) {
            const allEdges = Object.keys(window.networkx.body.edges);
            const edgesToUpdate = [];
            for (const edgeId of allEdges) {
                if (edges.includes(edgeId)) {
                    edgesToUpdate.push({
                        id: edgeId,
                        color: {
                            color: "#343434",
                        },
                        width: 2,
                    });
                } else {
                    edgesToUpdate.push({
                        id: edgeId,
                        color: {
                            opacity: 0.2,
                        },
                        width: 1,
                    });
                }
            }
            window.networkx.body.data.edges.update(edgesToUpdate);
        },

        getEdges(nodeId) {
            return window.networkx.getConnectedEdges(nodeId);
        },

        findConnectedPath(nodes) {
            let arr = [];
            let vertices = nodes;
            //MOTE (Kshitij) : o(n)^2 , can it be modified in better ways?
            for (let u of vertices) {
                for (let v of vertices) {
                    if (u !== v) {
                        let u_edges = new Set(this.getEdges(u));
                        let v_edges = new Set(this.getEdges(v));
                        let intersect = [...u_edges].filter((e) => v_edges.has(e));
                        if (intersect.length > 0) {
                            arr = arr.concat(intersect);
                        }
                    }
                }
            }
            return arr;
        },

        // findConnectedPath(nodes) {
        //   let connectedPath = [];
        //   for (const nodeId of nodes) {
        //     const connectedEdges = window.networkx.getConnectedEdges(nodeId);
        //     connectedPath = connectedPath.concat(connectedEdges);
        //   }

        //   const set = new Set(connectedPath);

        //   const duplicates = connectedPath.filter(item => {
        //     if (set.has(item)) {
        //       set.delete(item);
        //     } else {
        //       return item;
        //     }
        //   });

        //   return duplicates;
        // },

        filterGraph() {
            const filters = this.graphFilters;

            var keep = [];
            if (filters.length >= 1) {
                var connectedNodes = [];
                for (let filter of filters) {
                    const nodeId = filter.filterVal.id;
                    keep.push(nodeId);
                    connectedNodes = window.networkx.getConnectedNodes(nodeId);
                    keep = keep.concat(connectedNodes);
                }

                // this.highlightNodes(keep)
                this.highlightEdges([]); //Passing an empty array as input is same as  fade down all edges.
                this.highlightNodes(keep);

                //NOTE: check if it can be cached.

                // this.visibleNodes = keep;
            } else {
                this.executeFilter();
                // this.clearShortestLinkHighlight();
            }

            this.highlightedNodes = this.highlightedNodes.concat(keep);
            this.filteredNodes = keep;
            // this.filteredNodes

            // this.$emit('FilterAppliedEvent')
        },

        filterGraphNodes() {
            if (this.graphFilters.nodeValues.length <= 0 && this.graphFilters.entityValues.length <= 0 && this.graphFilters.sourceFilter.length <= 0) {
                this.showAllGraphNodes();
                return;
            }

            //this.graphNodes.forEach(n=>this.$set(n, 'hidden', false))
            //create filters
            const filters = [
                {
                    searchKey: "filterByNodeValue",
                    searchValues: this.graphFilters.nodeValues.map((e) => e.label),
                },
                {
                    searchKey: "filterByEntity",
                    searchValues: this.graphFilters.entityValues.map((e) => e.label),
                },
                {
                    searchKey: "filterBySource",
                    searchValues: this.graphFilters.sourceFilter.map((e) => e.label),
                },
            ];

            //find the id of the node which has
            //been set as a filter.

            const nodeValuesFilters = filters[0]["searchValues"];
            const entityValuesFilters = filters[1]["searchValues"];
            const sourceValuesFilters = filters[2]["searchValues"];

            var nodesToFilter = this.graphNodes.filter((nn) => {
                return nodeValuesFilters.includes(nn.label);
            });

            const nodesToFilterByEntity = this.graphNodes.filter((nn) => {
                return entityValuesFilters.includes(nn.searchType);
            });

            const nodesToFilterBySource = this.graphNodes.filter((nn) => {
                return sourceValuesFilters.includes(nn.source);
            });

            nodesToFilter = nodesToFilter.concat(nodesToFilterByEntity).concat(nodesToFilterBySource);

            //Hide nodes based on filter.

            //Store connected edges

            var nodesToKeep = [];
            nodesToFilter.forEach((n) => {
                this.graphLinks.forEach((node) => {
                    if (node.from === n.id || node.to === n.id) {
                        nodesToKeep.push(node.from);
                        nodesToKeep.push(node.to);
                    }
                });
            });

            this.graphNodes.forEach((n) => {
                if (!nodesToKeep.includes(n.id)) {
                    n.hidden = true;
                } else {
                    n.hidden = false;
                }
            });

            //window.networkx.redraw()
            this.render = !this.render;
        },

        // filterGraphNodes() {
        //   if (this.graphFilters.nodeValues.length <= 0 && this.graphFilters.entityValues.length <= 0 && this.graphFilters.sourceFilter.length <= 0) {
        //     this.showAllGraphNodes();
        //     return;
        //   }

        //   //this.graphNodes.forEach(n=>this.$set(n, 'hidden', false))
        //   //create filters
        //   const filters = [
        //     {
        //       searchKey: "filterByNodeValue",
        //       searchValues: this.graphFilters.nodeValues.map((e) => e.label),
        //     },
        //     {
        //       searchKey: "filterByEntity",
        //       searchValues: this.graphFilters.entityValues.map((e) => e.label),
        //     },
        //     {
        //       searchKey: "filterBySource",
        //       searchValues: this.graphFilters.sourceFilter.map((e) => e.label),
        //     },
        //   ];

        //   //find the id of the node which has
        //   //been set as a filter.

        //   const nodeValuesFilters = filters[0]["searchValues"];
        //   const entityValuesFilters = filters[1]["searchValues"];
        //   const sourceValuesFilters = filters[2]["searchValues"];

        //   var nodesToFilter = this.graphNodes.filter((nn) => {
        //     return nodeValuesFilters.includes(nn.label);
        //   });

        //   const nodesToFilterByEntity = this.graphNodes.filter((nn) => {
        //     return entityValuesFilters.includes(nn.searchType);
        //   });

        //   const nodesToFilterBySource = this.graphNodes.filter((nn) => {
        //     return sourceValuesFilters.includes(nn.source);
        //   });

        //   nodesToFilter = nodesToFilter.concat(nodesToFilterByEntity).concat(nodesToFilterBySource);

        //   //Hide nodes based on filter.

        //   //Store connected edges

        //   var nodesToKeep = [];
        //   nodesToFilter.forEach((n) => {
        //     this.graphLinks.forEach((node) => {
        //       if (node.from === n.id || node.to === n.id) {
        //         nodesToKeep.push(node.from);
        //         nodesToKeep.push(node.to);
        //       }
        //     });
        //   });

        //   this.graphNodes.forEach((n) => {
        //     if (!nodesToKeep.includes(n.id)) {
        //       n.hidden = true;
        //     } else {
        //       n.hidden = false;
        //     }
        //   });

        //   //window.networkx.redraw()
        //   this.render = !this.render;
        // },

        updateNodes(nodes) {
            if (nodes.length > 0) {
                let new_nodes = [
                    ...nodes.map((e) => {
                        return {...e};
                    }),
                ];

                for (let n of new_nodes) {
                    if (n.isCaseData === true) {
                        this.highlightedNodes.push(n.id);
                    }
                }

                // let new_nodes = [];

                // for(let n of nodes){
                //   if(n.isCaseData){

                //   }
                // }
                this.addImagesToNodes(new_nodes);

                window.networkx.body.data.nodes.update(new_nodes);
                // window.networkx.redraw();
            } else {
                window.networkx.setData({nodes: [], edges: []});
            }
            this.executeFilter();
        },
        updateLinks(links) {
            window.networkx.body.data.edges.update(links);
            // window.networkx.redraw();
        },

        getOptionsPopupRef() {
            return document.getElementById("cn-wrapper");
        },

        handleOptionsPopupOnZoom(network) {
            network.on("zoom", (ctx) => {
                const popup = this.getOptionsPopupRef();
                popup.style.display = "none;";
            });
        },

        genGraph() {
            var container = document.getElementById("networkx");
            //To remove same id obj
            const unique = [];

            this.graphNodes.forEach((x) => (unique.filter((a) => a.id == x.id).length > 0 ? null : unique.push(x)));

            this.addImagesToNodes(unique);
            let data = {
                nodes: new DataSet(unique),
                edges: new DataSet(this.graphLinks),
            };
            // "edges": {
            //     "smooth": {
            //       "forceDirection": "none"
            //     }
            //   },
            //   "physics": {
            //     "forceAtlas2Based": {
            //       "springLength": 0,
            //       "springConstant": 0.115
            //     },
            //     "minVelocity": 0.21,
            //     "solver": "forceAtlas2Based"
            //   }

            var options = {
                physics: {
                    enabled: false,
                    // forceAtlas2Based: {
                    //     gravitationalConstant: -26,
                    //     centralGravity: 0.005,
                    //     springLength: 230,
                    //     springConstant: 0.18,
                    // },
                    // maxVelocity: 146,
                    // solver: "forceAtlas2Based",
                    // timestep: 0.35,
                    // stabilization: { iterations: 600 },
                },

                nodes: {
                    size: 40,
                    font: {color: "#eeeeee"},
                    imagePadding: 8,
                    shapeProperties: {
                        useBorderWithImage: true,
                    },
                },
                edges: {
                    width: 1,
                    color: "#282C35",
                    smooth: {
                        forceDirection: "none",
                    },
                },

                layout: {improvedLayout: true},

                interaction: {
                    tooltipDelay: 200,
                    hover: true,
                },
            };
            window.networkx = new Network(container, data, options);
            // this.handleOptionsPopupOnZoom(window.networkx)

            window.networkx.on("zoom", (ctx) => {
                const popup = this.getOptionsPopupRef();
                popup.style.display = "none";
            });

            window.networkx.on("click", (ctx) => {
                //hide options popup
                const popup = this.getOptionsPopupRef();
                popup.style.display = "none";
            });

            window.networkx.on("stabilizationIterationsDone", () => {
                // window.network.stopSimulation()
                var physix = {
                    physics: {
                        enabled: false,
                    },
                };
                window.networkx.setOptions(physix);
                document.getElementById("stable-percent").innerText = "100%";
                this.stabilizing = false;
                this.saveGraphData();

                // document.getElementById("bar").style.width = "800px";
                // document.getElementById("bar").style.opacity = 0;
                // really clean the dom element
                // setTimeout(function () {
                //     document.getElementById("bar").style.display = "none";
                // }, 500);
            });
            window.networkx.on("stabilizationProgress", (params) => {
                var maxWidth = 800;
                var minWidth = 20;
                var widthFactor = params.iterations / params.total;
                var width = Math.max(minWidth, maxWidth * widthFactor);
                // this.graphStabilizationPercentage = Math.max(minWidth, maxWidth * widthFactor)

                document.getElementById("stable-percent").innerText = Math.round(widthFactor * 100) + "%";
            });
            // window.networkx.on('stabilized', ctx => {
            //   // this.saveGraphData()

            // })

            window.networkx.on("hoverNode", (params) => {});
            // window.networkx.on("hoverEdge", params => {
            // })
            window.networkx.on("oncontext", (ctx) => {
                const nodeId = window.networkx.getNodeAt(ctx.pointer.DOM);
                this.rightClickedNode = nodeId;

                var nodePosition = ctx.pointer.DOM;
                // var domPos = window.networkx.getPosition()
                var optionsPopup = document.getElementById("cn-wrapper");
                optionsPopup.style.display = "none";

                // optionsPopup.style.transform = "scale(0.3)"
                // optionsPopup.style.transition = "transform 0.1s"

                // var domPos = window.networkx.canvasToDOM(pos)

                let rect = optionsPopup.getBoundingClientRect();
                let width = rect.right - rect.left;
                let height = rect.bottom - rect.top;

                optionsPopup.style.top = `${nodePosition.y - height / 2}px`;
                optionsPopup.style.left = `${nodePosition.x - width / 2}px`;

                optionsPopup.style.display = "block";

                // rect = optionsPopup.getBoundingClientRect();
                // width = rect.right - rect.left;
                // height = rect.bottom - rect.top;

                optionsPopup.style.top = `${nodePosition.y - 113}px`;
                optionsPopup.style.left = `${nodePosition.x - 113}px`;
                // optionsPopup.style.transform = "scale(1.3)"
                // optionsPopup.style.transition = "transform 2s"

                // var nodePosition = window.networkx.getPositions([nodeId]);

                // window.networkx.on("afterDrawing", function (ctx) {
                //   // var nodeId = 'twitter__Kill Switch'
                //   var nodePosition = window.networkx.getPositions([nodeId]);
                //   ctx.strokeStyle = "#294475";
                //   ctx.lineWidth = 4;
                //   ctx.fillStyle = "#A6D5F7";

                //   ctx.beginPath();
                //   ctx.arc(
                //     nodePosition[nodeId].x,
                //     nodePosition[nodeId].y,
                //     80,
                //     0,
                //     2 * Math.PI,
                //     false
                //   );
                //   ctx.closePath();

                //   ctx.fill();
                //   ctx.stroke();
                // });
            });

            // window.networkx.on("beforeDrawing", function (ctx) {
            //   var nodeId = 'twitter__Kill Switch'

            //   var nodePosition = window.networkx.getPositions([nodeId]);
            //   ctx.strokeStyle = "#A6D5F7";
            //   ctx.fillStyle = "#294475";

            //   ctx.beginPath();
            //   ctx.arc(
            //     nodePosition[nodeId].x,
            //     nodePosition[nodeId].y,
            //     50,
            //     0,
            //     2 * Math.PI,
            //     false
            //   );
            //   ctx.closePath();

            //   ctx.fill();
            //   ctx.stroke();
            // });
            // window.networkx.on("afterDrawing", function (ctx) {
            //   var nodeId = 'twitter__Kill Switch'
            //   var nodePosition = window.networkx.getPositions([nodeId]);
            //   ctx.strokeStyle = "#294475";
            //   ctx.lineWidth = 4;
            //   ctx.fillStyle = "#A6D5F7";

            //   ctx.beginPath();
            //   ctx.arc(
            //     nodePosition[nodeId].x,
            //     nodePosition[nodeId].y,
            //     20,
            //     0,
            //     2 * Math.PI,
            //     false
            //   );
            //   ctx.closePath();

            //   ctx.fill();
            //   ctx.stroke();
            // });
        },

        hideGraphPopup() {
            const popup = this.getOptionsPopupRef();
            popup.style.display = "none";
        },
        getImageForNodes(node) {
            // let icons = [...this.$store.getters.getIconsData, ...this.$store.getters.getSocialPlatforms];
            let type = node.type;
            let config = null;
            switch (type) {
                case "multi_query_center_node":
                    config = {shape: "box", color: {font: "black"}};
                    break;
                case "username_search_key":
                    config = {image: require("@/assets/icons/graph/user_1.png"), shape: "image", size: 30, font: {color: "#6B45C6"}};
                    break;
                case "followers":
                    config = {image: require("@/assets/icons/graph/user.png"), size: 25, font: {color: "#38314C"}};
                    break;
                case "username":
                case "name":
                    config = {image: require("@/assets/icons/graph/user.png"), size: 25, font: {color: "#38314C"}};
                    break;
                case "current_employer":
                    config = {image: require("@/assets/icons/graph/job.svg"), size: 25, font: {color: "#38314C"}};
                    break;
                case "current_title":
                    config = {image: require("@/assets/icons/graph/employees.svg"), size: 25, font: {color: "#38314C"}};
                    break;
                case "phone":
                case "contact":
                    config = {image: require("@/assets/icons/graph/phone-call.png"), size: 25, font: {color: "#38314C"}};

                    break;
                case "email":
                    config = {image: require("@/assets/icons/graph/email.png"), size: 25, font: {color: "#38314C"}};
                    break;
                case "location":
                case "city":
                case "country":
                case "state":
                    config = {image: require("@/assets/icons/graph/location.png"), size: 25, font: {color: "#38314C"}};
                    break;
                case "followers":
                    config = {image: "https://w7.pngwing.com/pngs/858/581/png-transparent-profile-icon-user-computer-icons-system-chinese-wind-title-column-miscellaneous-service-logo.png", size: 25, font: {color: "#38314C"}};
                    break;
                default: {
                    // let iconFound = false;
                    // icons.forEach(iconDoc => {
                    //   if (type === iconDoc.key) {
                    //     config = { image: iconDoc.icon_data, size: 30, font: { color: '#6B45C6' } };
                    //     iconFound = true;
                    //   }
                    // });
                    if (this.iconCache[type] !== undefined) {
                        config = {image: this.iconCache[type], size: 25, font: {color: "#38314C"}};
                    } else {
                        config = {image: require("@/assets/icons/graph/default.png"), size: 25, font: {color: "#38314C"}};
                    }
                    // if (!iconFound)
                    //   config = { image: require("@/assets/icons/graph/default.png"), size: 25, font: { color: '#38314C' } };
                }
            }
            if (node.isSubQueryNode === true) {
                config.shape = "image";
                config.borderWidth = 3;
            }
            return config;
        },

        addImagesToNodes(nodes) {
            // NOTE : some old graphs were saved, reset those styles temporarily & fix them later on.

            nodes.forEach((node) => {
                if (node.type !== "multi_query_center_node") node.shape = "circularImage";

                const attrs = this.getImageForNodes(node);
                if (attrs.shape) {
                    node.shape = attrs.shape;
                }
                if (attrs.font) {
                    node.font = attrs.font;
                }
                if (attrs.size) {
                    node.size = attrs.size;
                }
                // node.color = {};
                // node.color.background = "#fff";
                node.image = attrs.image;

                if (attrs !== undefined && node.type !== "multi_query_center_node" && !node.isSubQueryNode) {
                    node.color = "#fff";
                    node.borderWidth = 0;
                    // node.shape = "image"
                }

                // if (node.isSubQueryNode === true) {
                //   node.shape = "image";
                //   node.borderWidth = 3;
                // }
            });

            // return nodes
        },

        async search() {
            const inputs = this.formControls[this.searchType][this.searchFilterType];
            let nodeToLink = inputs.nodesAttachTo;
            let nodeIdToLink = null;
            let searchNodes = [];
            let searchLinks = [];
            for (const [inp, value] of Object.entries(inputs)) {
                if ("nodesAttachTo" === inp) {
                    continue;
                }
                let _node = {id: this.nodeCount, name: value};
                searchNodes.push(_node);
                this.nodeCount = this.nodeCount + 1;
            }
            // FInd the id of node to which search results has to be attached.
            nodeIdToLink = searchNodes[0].id;
            //Generate Links between search Nodes
            let id_0 = searchNodes[0].id;
            for (var i = 1; i < searchNodes.length; i++) {
                searchLinks.push({sid: id_0, tid: searchNodes[i].id});
            }

            //batch insert nodes & links
            this.nodes.push(...searchNodes);
            this.links.push(...searchLinks);
            // return;

            const apis = this.filterValueApiMap[this.searchType].filter((e) => e.source === this.searchFilterType);
            await this.fetchDataFromApis(apis, nodeIdToLink);
        },

        makeNode(from, toNodes) {},

        extractData(data, queryInfo) {
            var result = data.filter((d) => {
                for (const x of d) {
                    if (queryInfo.keys.includes(d[0])) return true;
                    return false;
                }
            });
            return result;
        },

        async fetchDataFromApis(endpoints, searchNodeId) {
            //  if(this.searchType === 'username')
            for (const obj of endpoints) {
                let url = obj.api;

                let body = this.formControls[this.searchType][this.searchFilterType];
                delete body.nodesAttachTo;

                let nodesFound = [];
                let linksFound = [];

                try {
                    let data = await osintDataUrl.post(url, body, {timeout: 10000 * 5});
                    data = this.flattenDict(data.data);
                    let extractedData = this.extractData(data, obj);
                    extractedData.forEach((d) => {
                        let node = {
                            id: this.nodeCount,
                            name: d[1],
                            type: d[0],
                            source: obj.source,
                        };
                        nodesFound.push(node);
                        linksFound.push({sid: searchNodeId, tid: this.nodeCount});
                        this.nodeCount = this.nodeCount + 1;
                    });

                    // return;

                    //Find connection with the existing nodes,
                    const nodeValues = nodesFound.map((e) => e.name);
                    const existingNodeValues = this.nodes.map((e) => e.name);
                    let linkedNode = null;
                    let link = nodeValues.forEach((value, index) => {
                        existingNodeValues.forEach((existing_value, idx) => {
                            let e_values = existing_value.split(" ").map((x) => x.toLowerCase());

                            let link = value
                                .split()
                                .map((e) => e.toLowerCase())
                                .some((item) => e_values.includes(item));

                            if (link) {
                                {
                                    linksFound.push({
                                        sid: nodesFound[index].id,
                                        tid: this.nodes[idx].id,
                                    });
                                }
                            }
                        });
                    });

                    this.nodes.push(...nodesFound);
                    this.links.push(...linksFound);
                    // if(linkedNode){
                    // let nodeIdToLink = this.nodes[linkedNode].id
                    // Build Links

                    // nodesFound.forEach(nn=>this.links.push({sid:nodeIdToLink, tid:nn.id}))

                    // }
                } catch (err) {}
            }
        },

        createNetwork(existingData, incomingdata) {
            if (existingData.length === 0) {
                existingData.push(incomingdata);
            } else {
                var values = incomingdata["data"].map((d) => d[1]);
                existingData.forEach((d) => {
                    var d_set = new Set(d["data"].map((x) => x[1]));
                    let intersect = values.filter((i) => d_set.has(i));
                    if (intersect.length >= 1) {
                        //There shoul be a links
                        d["links"].push(incomingdata);
                    }
                });
            }
        },

        removeDirtyKeys(data, source) {
            var dirtyKeys = this.ignoreKeysMap[source];
            var result = data.filter((d) => {
                // if(dirtyKeys.includes(d[0])) return false;
                for (const x of dirtyKeys) {
                    if (d[0].startsWith(x) || d[1] === "null" || typeof d[1] === "boolean" || typeof d[1] === "number") return false;
                }
                return true;
            });
            return result;
        },
        applyKeyFormatters(data, source) {
            if (!(source in this.keyFormatters)) {
                return data;
            }

            const formatter = this.keyFormatters[source];

            let foramttedData = data.map((d) => {
                let [k, v] = d;
                if (k in formatter) {
                    return [k, formatter[k](v).toLowerCase()];
                } else {
                    return [k, v.toLowerCase()];
                }
            });

            return {source: source, data: foramttedData, links: []};
        },

        flattenDict(obj, acc = []) {
            if (typeof obj === "object") {
                for (let x in obj) {
                    if (typeof obj[x] === "object") {
                        this.flattenDict(obj[x], acc);
                    } else if (typeof obj[x] === "array") {
                        for (a in obj[x]) {
                            this.flattenDict(a, acc);
                        }
                    } else {
                        acc.push([x, obj[x]]);
                    }
                }
            }
            return acc;
        },

        handleLegends(name) {
            return this.graphNodes?.some((n) => n.group == name);
        },

        // async fetchDataFromApis(endpoints){
        //     endpoints.forEach(api => {

        //         try{
        //             let data = await osintDataUrl.post(api)

        //         }catch(err){
        //         }
        //     });

        // },
    },
};
